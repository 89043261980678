<template>
  <van-dialog
    class="pb-2 w-75"
    v-model:show="showDialog"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
    @close="onClose"
  >
    <div class="px-2 pt-2 pb-2 text-center">
      {{ title ? title : text }}
    </div>
    <div v-if="title" class="px-2 pb-2 text-center text-14 text-normal text-dark">
      {{ text }}
    </div>
    <div class="text-center text-13 mb-0">
      <button
        v-if="showCancelBtn"
        class="px-3 py-1 mr-1 bg-white rounded-20px border-dark"
        @click="onClose"
      >
        {{ cancelText }}
      </button>
      <button
        class="px-3 py-1 bg-yellow rounded-20px border-yellow"
        @click="onSubmit"
      >
        {{ buttonText }}
      </button>
    </div>
  </van-dialog>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'

const emits = defineEmits(['submit', 'close'])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: '我知道了',
  },
  title: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    required: true,
  },
  showCancelBtn: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: '取消',
  },
})

const showDialog = ref(false)

onMounted(() => {
  showDialog.value = props.show
})

watch(
  () => props.show,
  (value) => {
    showDialog.value = value
  }
)

const onSubmit = () => {
  emits('submit')
}

const onClose = () => {
  emits('close')
}
</script>
