import * as Vue from 'vue'
const routes = [
  {
    name: 'order.recharge',
    path: '/order/recharge',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/Recharge.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'order.bill',
    path: '/order/bill',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/Bill.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'order.bill.global',
    path: '/order/bill-global',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/BillGlobal.vue'))
  },
  {
    name: 'order.result',
    path: '/order/result',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/Result.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'order.free-result',
    path: '/order/free-result',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/FreeResult.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'order.result.global',
    path: '/order/result-global/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/ResultGlobal.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'order.broswer',
    path: '/order/broswer',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/Broswer.vue'))
  },
  {
    name: 'order.paypal',
    path: '/order/:id/paypal',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/PaypalOrder.vue'))
  },
  {
    name: 'order.airwallex',
    path: '/order/:id/airwallex_card',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/AirwallexOrder.vue'))
  },
  {
    name: 'order.stripeOrder',
    path: '/order/:id/stripe',
    component: Vue.defineAsyncComponent(() => import('@/pages/order/StripeOrder.vue'))
  }
]

export default routes
